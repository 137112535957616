import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import machineImage from './../images/360/machine.png'
import machineImageLarge from './../images/360/machine@2x.png'
import { TweenMax } from "gsap/all"
import { animationType, createAnimationType, doAnimate, nl2br } from '../utils/functions'
import { ImageRef } from './../components/ux/image';
import { connect } from 'react-redux'

function ThreeSixty({ translations, lang }) {

    const [rendered, setRendered] = useState(false)

    const text1Ref = useRef();
    const text2Ref = useRef();
    const imageRef = useRef();
    const swingerRef = useRef();

    createAnimationType();

    useEffect(() => {
        if (doAnimate()) {
            if (rendered !== true) {
                setRendered(true)
                setTimeout(() => {

                    TweenMax.from(text1Ref.current, 1, { ease: animationType, x: -150, y: -10, autoAlpha: 0 });
                    TweenMax.from(text2Ref.current, 1, { ease: animationType, x: -150, y: 10, autoAlpha: 0 });
                    TweenMax.from(imageRef.current, 1, { ease: animationType, x: 150, autoAlpha: 0 });
                    TweenMax.from(swingerRef.current, 1, { ease: animationType, x: 300, autoAlpha: 0 });

                }, 250);
            }
        }
    }, [rendered]);

    return (
        <Layout>
            <SEO title={translations.threesixty_title} description={translations.threesixty_meta_description} />
            <div className="threesixty subpage">
                <div className="lightholder"></div>
                <section className="one with-footer">
                    <div className="swinger animationElement" ref={swingerRef}></div>
                    <div className="inner-wrapper">
                        <div>
                            <div ref={text1Ref} className="animationElement">
                                <h1>
                                    {nl2br(translations.threesixty_headline_white)}<br />
                                    <span className="yellow">
                                        {
                                            nl2br(translations.threesixty_headline_yellow)
                                        }
                                    </span>
                                </h1>
                                <p className="t-upper mb mt bolder">
                                    {
                                        nl2br(translations.threesixty_subline)
                                    }
                                </p>
                            </div>
                            <div ref={text2Ref} className="animationElement">
                                <p className="light">
                                    {nl2br(translations.threesixty_description)}
                                </p>
                            </div>
                        </div>
                        <div ref={imageRef} className="animationElement" >
                            <ImageRef img={machineImage} img2={machineImageLarge} alt="Machine Image" className="machine-image scale-80" />
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(ThreeSixty)